<script setup lang="ts">
import { ref } from 'vue';

import AppMenuItem from './AppMenuItem.vue';
import {EPermissaoSistema} from "@/stores/enums/EPermissaoSistema";
import {useAuthStore} from "@/stores/auth.store";
const authStore = useAuthStore();

const model :any = ref([
    {
        label: 'Home',
        items: [{ label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' }]
    },
    {
        label: 'Menu',
      items: [
        {
          label: "Empresas", icon: "pi pi-fw pi-building", to: {name: 'empresas'}
        },
        {
          label: "Avisos", icon: "pi pi-fw pi-bell", to: {name: 'avisos'}
        },
        {
          label: 'Página Inicial/News', icon:"pi pi-fw pi-info-circle" ,to: {name:"empresa_newsletter"}
        },

        {
          label: 'Contratos',
          icon: 'pi pi-fw pi-file-edit',
          to: {name: "contratos"},
          visible: authStore.temPermissao(EPermissaoSistema.CONTRATO)


        },
        {
          label: 'Chamados',
          icon: 'pi pi-fw pi-list',
          to: {name: "chamados"},
          visible: authStore.temPermissao(EPermissaoSistema.CHAMADO)
        },
        {
          label: 'Assuntos',
          icon: 'pi pi-fw pi-copy',
          to: "/assuntos",
          visible: authStore.temPermissao(EPermissaoSistema.ASSUNTO)

        },


      ]
    },
]);
</script>

<template>
    <ul class="layout-menu">
        <template v-for="(item, i) in model" :key="item">
            <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
            <li v-if="item.separator" class="menu-separator"></li>
        </template>
    </ul>
</template>

<style lang="scss" scoped></style>
