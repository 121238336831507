import {type Ref, ref} from "vue";
import {defineStore} from "pinia";

import AuthService from "@/services/auth.service";
import type {LoginCommand} from "@/commands/auth.commands";
import type {EPermissaoSistema} from "@/stores/enums/EPermissaoSistema";
import {EStatusUsuario} from "@/type/usuario.type";


const userStorage = localStorage.getItem('user');
const userNameStorage = localStorage.getItem('userName');
const userEmailStorage = localStorage.getItem("userEmail");
const userStatusStorage = localStorage.getItem("userStatus");
const userImageStore = localStorage.getItem('userImage');
const userPublicIdStorage = localStorage.getItem('userPublicId');


export const useAuthStore = defineStore('authStore', () => {


    const user = ref(userStorage);
    const userName = ref(userNameStorage);
    const userEmail = ref(userEmailStorage);
    const userStatus: Ref<string | EStatusUsuario | null> = ref(userStatusStorage);
    const userImage = ref(userImageStore);
    const userPublicId = ref(userPublicIdStorage);

    const usuarioPermissoes = ref();
    const usuarioTemporario = ref(false);

    function activateUsuarioTemporario() {


        usuarioTemporario.value = true;
        userName.value = "JOSNEY";
        userEmail.value = "batatinha@123.com"
        userStatus.value = EStatusUsuario.Cadastrado;
        userImage.value = "";
        userPublicId.value = "public id 123";

        usuarioPermissoes.value = ["0001", "0002", "0003", "0004", "0005", "0006", "0007", "0008", "0009", "0010"];
        //usuarioPermissoes.value = [];



        localStorage.setItem('user', "123");
        localStorage.setItem('userName', userName.value);
        localStorage.setItem('userEmail', userEmail.value);
        localStorage.setItem('userStatus', userStatus.value.toString());
        localStorage.setItem('userImage', userImage.value);
        localStorage.setItem('userPublicId', userPublicId.value);
        localStorage.setItem('userTemp', "1");

    }


    function isLoggedIn() {
        return !!localStorage.getItem('user');
    }

    function entrar(user: LoginCommand) {

        if (import.meta.env.MODE == "development" && user.usuario == "teste" && user.senha == "teste") {
            activateUsuarioTemporario();
            return Promise.resolve(true);
        }


        return AuthService
            .login(user)
            .then((userRet: any) => {

                userName.value = userRet.nome;
                userEmail.value = userRet.email;
                userStatus.value = userRet.statusUsuario;
                userImage.value = userRet.imagem;
                userPublicId.value = userRet.publicId;

                setPermissoes(userRet.permissoes);

                return Promise.resolve(userRet);

            })
            .catch((error) => {
                return Promise.reject(error);
            });


    }

    function sair() {
        AuthService.logout();
        usuarioPermissoes.value = null;
    }

    function setUserName(apelido: string) {
        userName.value = apelido;
        localStorage.setItem('userName', apelido);
    }

    function setUserStatus(statusUsuario: EStatusUsuario) {
        userStatus.value = statusUsuario;
        localStorage.setItem('userStatus', statusUsuario.toString());
    }

    function setUserEmail(email: string) {
        userEmail.value = email;
        localStorage.setItem('userEmail', email);
    }

    function setPermissoes(permissoes: any) {
        usuarioPermissoes.value = permissoes;

    }

    async function verificaPermissoesStore() {

        if (import.meta.env.MODE == "development" && localStorage.getItem('userTemp') == "1" && usuarioPermissoes.value == null) {
            usuarioPermissoes.value = ["0001", "0002", "0003", "0004", "0005", "0006", "0007", "0008", "0009", "0010"];
        }


        try {
            if (usuarioPermissoes.value == null) {

                const ret = await AuthService.obterPermissoes();

                setPermissoes(ret.data.permissoes);
            }
            return true;
        } catch (e) {
            localStorage.removeItem('user');
            return false;
        }

    }

    function temPermissao(permissao: EPermissaoSistema) {


        try {
            return !!usuarioPermissoes.value.includes(permissao);

        } catch (e) {
            return false;
        }


    }


    return {
        user,
        userName,
        userEmail,
        userStatus,
        userImage,
        isLoggedIn,
        userPublicId,
        entrar,
        sair,
        setUserName,
        setUserStatus,
        setUserEmail,
        usuarioPermissoes,
        verificaPermissoesStore,
        temPermissao
    }
});



