<script setup lang="ts">

import {ref} from "vue";
import {useRouter} from "vue-router";
import {useAuthStore} from "@/stores/auth.store";

const router = useRouter();
const authStore = useAuthStore();


const userMenu = ref();
const itemsUserMenu = ref([
  {separator: true},
  {
    label: 'Perfíl',
    icon: 'pi pi-fw pi-user',
    command: () => {
      router.push({name: "perfil"})
    }

  },
  {
    label: 'Configurações',
    icon: 'pi pi-fw pi-cog',
    badge: 2,
    command: () => {
      router.push({name: 'perfil', params: {c: "config"}})
    }
  },
  {separator: true},


  {
    label: 'Sair',
    icon: 'pi pi-fw pi-sign-out',
     command:()=>onLogout()
  },


]);


const onUserMenuToggle = (event: Event) => {
  userMenu.value.toggle(event);
}

const onLogout = () => {
  authStore.sair();
  router.push({name: 'signin'})

}

</script>

<template>

  <Avatar :icon="(authStore.userImage !== ''? '' : 'pi pi-user' )" :image="authStore.userImage??''" class="mr-2 cursor-pointer layout-user-avatar-button " size="xlarge" aria-haspopup="true"
          @click="onUserMenuToggle"
          aria-controls="overlay_user_menu" shape="circle">


  </Avatar>

  <Menu ref="userMenu" :model="itemsUserMenu" id="overlay_user_menu" :popup="true">
    <template #start>
      <button
          class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">

        <div class="flex flex-column align">
          <span class="font-bold">{{authStore.userName}}</span>
        </div>
      </button>
    </template>

  </Menu>
</template>

<style scoped>

</style>