export interface UsuarioInterno {
    publicId: string;
    nomeCompleto: string;
    apelido: string;
    cpf: string;
    celular: string;
    imagem: string;
    email: string;
    statusUsuario: EStatusUsuario;
    statusUsuarioDescricao: string;

}

export enum EStatusUsuario {
    Cadastrado = 1,
    Confirmado = 2
}