import { ref } from 'vue'
import { defineStore } from 'pinia'


export const useHomeStore = defineStore('homeStore', () => {

    const breadCrumbItens = ref(null);
    const appTitle = ref(import.meta.env.VITE_APP_TITLE);

    const isShowPaceLoading = ref(false);

    // const doubleCount = computed(() => count.value * 2)
    function setBreadCrumbItens(itens : any) {
        breadCrumbItens.value = itens;
    }

    function setPageTitle(title : string) {
        document.title = title + " - " + appTitle.value;
    }

    return { breadCrumbItens, setBreadCrumbItens,setPageTitle,isShowPaceLoading }
})
