export enum EPermissaoSistema {

    CHAMADO = "0001",
    CLIENTE = "0003",
    CONTRATO = "0004",
    ASSUNTO = "0005",
    ASSUNTO_ALTERAR = "0006",
    ASSUNTO_REMOVER = "0007",
    CONTRATO_GERENCIAR = "0008",
    ASSUNTO_ALTERAR_RESPONSAVEL = "0009",
    USUARIO_GERENCIAR = "0010",

}