import {useToast} from "primevue/usetoast";
import type {DefaultResponse} from "@/type/request.type";


export const useToastHelper = () => {

    const toast = useToast();

    const showToastError = (titulo: string, mensagem: string) => {

        toast.add({severity: 'error', summary: titulo, detail: mensagem, group: "br", life: 4000});
    }

    const showToastSuccess = (titulo: string, mensagem: string) =>
    {
        toast.add({severity: "success", summary: titulo.toUpperCase(), detail: mensagem.toUpperCase(), group: "br", life: 3500})
    }


    const showRequestError = (error: DefaultResponse<string>) => {

        showToastError(error.message == "" || error.message == null ? error.data : error.message,
            error.message == "" || error.message == null ? "" : error.data)
    }


    return {showRequestError, showToastError,showToastSuccess}
}