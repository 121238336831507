<script setup lang="ts">
import {onMounted} from "vue";
import {useHomeStore} from "@/stores/home.store";
import {removeAccents} from "@primeuix/utils/object";
import {FilterService} from '@primevue/core/api';

const homeStore = useHomeStore();


if (import.meta.env.MODE == "development" || import.meta.env.MODE == "test") {
  console.log("API URL: " + import.meta.env.VITE_API_URL + "\n" + "APP URL: " + import.meta.env.VITE_APP_BASE_URL);
}


FilterService.register("UFILTER_CONTAINS", (value: any, filter: any, filterLocale: any) => {
  if (filter === undefined || filter === null || filter === '') {
    return true;
  }
  if (value === undefined || value === null) {
    return false;
  }
  var filterValue = removeAccents(filter.toString().trim()).toLocaleLowerCase(filterLocale);
  var stringValue = removeAccents(value.toString()).toLocaleLowerCase(filterLocale);


  if (!(stringValue.indexOf(filterValue) !== -1)) {

    filterValue = removeAccents(filter.toString().trim().replaceAll(" ", "")).toLocaleLowerCase(filterLocale);
    stringValue = removeAccents(value.toString().trim().replaceAll(" ", "")).toLocaleLowerCase(filterLocale);

    return stringValue.indexOf(filterValue) !== -1;
  } else {
    return true;
  }

});


onMounted(() => {

})


</script>

<template>

  <div v-if="homeStore.isShowPaceLoading" class=" bg-amber-300 fixed w-full flex justify-center" style="z-index: 998">
    <ProgressBar mode="indeterminate" style="height: 7px;" class="w-full"></ProgressBar>
  </div>


  <Toast/>
  <Toast position="bottom-right" group="br"/>


  <ConfirmPopup></ConfirmPopup>

  <ConfirmDialog group="cd"></ConfirmDialog>


  <ConfirmDialog group="cd-stylized">
    <template #message="slotProps">
      <div class="flex flex-col items-center w-full gap-4 border-b border-surface-200 dark:border-surface-700">
        <i :class="slotProps.message.icon" class="!text-6xl text-primary-500"></i>
        <p>{{ slotProps.message.message }}</p>
      </div>
    </template>
  </ConfirmDialog>






  <DynamicDialog/>


  <router-view/>


  <!--GAMBIARRA PARA SEMPRE INICIALIZAR ALGUNS COMPONENTES -->
  <div v-show="false" class="hidden" id="component-initilization">
    <DataTable :value="new Array(5)" striped-rows
               table-style="min-width: 50rem" sortField="dataConclusao" :sortOrder="1"
               paginator :rows="20" :rowsPerPageOptions="[10, 20, 50, 100]"
               paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
               currentPageReportTemplate="{first} to {last} of {totalRecords}"
               contextMenu>


      <template #header>
        <div class="flex flex-wrap gap-2.5 align-items-center justify-content-start">

          <IconField>
            <InputIcon class="pi pi-search"/>

            <InputText placeholder="Busca na grade..."/>
          </IconField>

          <InputNumber
              placeholder="Código 0000" :min="0" :use-grouping="false"/>

          <Button class="ml-5" label="Filtrar" icon="pi-filter-fill pi"></Button>

        </div>
      </template>

      <template #empty>Nenhum Contrato Encontrado.</template>


      <!--    Botão para visualizar a observacao-->
      <Column header="" field="observacao" style="max-width: 1px; width:1px">
        <template #body>
          <span>
            <i class="pi pi-eye cursor-pointer">

            </i>
          </span>

        </template>
      </Column>
    </DataTable>
  </div>
</template>

<style scoped></style>
